import React, { useState, useEffect, useRef } from 'react';

const FilterByFeature = ({
  categories,
  features,
  selectedCategories,
  selectedFeatures,
  selectedDistance,
  onCategoryChange,
  onFeatureChange,
  onDistanceChange,
}) => {
  const dropdownRef = useRef();

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Close the dropdown when clicking anywhere outside of it
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleCategoryChange = (category) => {
    onCategoryChange(category);
  };

  const handleFeatureChange = (feature) => {
    onFeatureChange(feature);
  };

  const handleDistanceChange = (distance) => {
    onDistanceChange(distance);
  };

  return (
    <div className={`filterbyfeature ${!isOpen ? 'hidden md:block' : ''}`}>
      <div className='relative inline-block text-left'>
        <button
          onClick={toggleDropdown}
          className='flex justify-between mx-5 bg-gray-50 border border-gray-300 dark:bg-[#454545] dark:text-white text-gray-900 md:text-sm text-md rounded-lg focus:ring-[#C0A04C] focus:border-[#C0A04C] block w-14 md:w-52 p-1.5 dark:border-[#454545] dark:text-white dark:focus:ring-[#C0A04C] dark:focus:border-[#C0A04C]'
        >
          <span className='ml-2 hidden md:block text-gray-500 dark:text-white'>
            Filter by Features
          </span>
          <span className='hidden block text-gray-500 dark:text-white'>
            Filter
          </span>
          <img
            className='dark:hidden h-5 w-5 flex'
            src='/images/icons/filter.svg'
            alt=''
          />
          <img
            className='hidden h-5 w-5 dark:flex'
            src='/images/icons/filter-light.svg'
            alt=''
          />
        </button>
        {isOpen && (
          <div
            className='ml-5  dark:bg-[#454545] dark:text-white origin-top-right absolute left-0 mt-2 h-80 overflow-y-auto w-52 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50 '
            ref={dropdownRef}
          >
            <div className='p-5'>
              <div className='popular'>
                <span className='font-semibold text-sm'>Categories</span>
                {categories &&
                  categories.map((category) => (
                    <div
                      className='flex items-center mb-1 mt-2'
                      key={category.categoryURL}
                    >
                      <input
                        id={category.categoryURL}
                        type='checkbox'
                        onChange={() => handleCategoryChange(category)}
                        defaultChecked={selectedCategories.includes(
                          category.categoryURL
                        )}
                        className='w-4 h-4 text-[#C0A04C] border-gray-300 rounded focus:ring-[#C0A04C] dark:focus:ring-[#C0A04C] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                      />
                      <label
                        htmlFor={category.categoryURL}
                        className='ml-2 text-sm font-normal text-gray-900 dark:text-gray-300'
                      >
                        {category.name}
                      </label>
                    </div>
                  ))}
                <span className='ml-0 font-semibold text-sm'>Features</span>
                {features &&
                  features.map((feature) => (
                    <div className='flex items-center mb-1 mt-2' key={feature}>
                      <input
                        id={feature}
                        type='checkbox'
                        onChange={() => handleFeatureChange(feature)}
                        checked={selectedFeatures.includes(feature)}
                        className='w-4 h-4 text-[#C0A04C] border-gray-300 rounded focus:ring-[#C0A04C] dark:focus:ring-[#C0A04C] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                      />
                      <label
                        htmlFor={feature}
                        className='ml-2 text-sm font-normal text-gray-900 dark:text-gray-300'
                      >
                        {feature}
                      </label>
                    </div>
                  ))}
              </div>

              <hr className='h-px my-3 bg-gray-500 border-0 dark:bg-gray-700' />

              <div className='distance'>
                <span className='ml-0 font-semibold text-sm'>
                  Distance From Muscat
                </span>
                <div className='flex items-center mb-1 mt-2'>
                  <input
                    onChange={() => handleDistanceChange(4)}
                    checked={selectedDistance.includes(4)}
                    id='4km'
                    type='checkbox'
                    className='w-4 h-4 text-[#C0A04C] border-gray-300 rounded focus:ring-[#C0A04C] dark:focus:ring-[#C0A04C] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                  />
                  <label
                    htmlFor='4km'
                    className='ml-2 text-sm font-normal text-gray-900 dark:text-gray-300'
                  >
                    Less than 4 km
                  </label>
                </div>

                <div className='flex items-center mb-1'>
                  <input
                    onChange={() => handleDistanceChange(10)}
                    checked={selectedDistance.includes(10)}
                    id='10km'
                    type='checkbox'
                    className='w-4 h-4 text-[#C0A04C] border-gray-300 rounded focus:ring-[#C0A04C] dark:focus:ring-[#C0A04C] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                  />
                  <label
                    htmlFor='10km'
                    className='ml-2 text-sm font-normal text-gray-900 dark:text-gray-300'
                  >
                    Less than 10 km
                  </label>
                </div>

                <div className='flex items-center mb-1'>
                  <input
                    id='more10km'
                    type='checkbox'
                    className='w-4 h-4 text-[#C0A04C] border-gray-300 rounded focus:ring-[#C0A04C] dark:focus:ring-[#C0A04C] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                  />
                  <label
                    htmlFor='more10km'
                    className='ml-2 text-sm font-normal text-gray-900 dark:text-gray-300'
                  >
                    More than 10 km
                  </label>
                </div>
              </div>

              <hr className='h-px my-3 bg-gray-500 border-0 dark:bg-gray-700' />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterByFeature;
