import React from 'react';
import EventCard from '../../../components/Cards/EventCard';
import Carousel from 'react-multi-carousel';

const YouMightAlsoLike = (upcomingEvents) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };
  return (
    <>
      <div className='flex items-center mt-10'>
        <div className='flex-1 border-t border-gray-400'></div>
        <span className='px-4 text-2xl font-bold '>
          You Might Also Like
        </span>
        <div className='flex-1 border-t border-gray-400'></div>
      </div>

      <div className='ml-2 mr-2 mt-5'>
        <div
          id='content'
          className='flex w-full justify-between overflow-x-auto'
        >
          <div className='w-full'>
            <Carousel responsive={responsive}>
              {upcomingEvents &&
                upcomingEvents.data.map((event) => (
                  <div>
                    <EventCard data={event} />
                  </div>
                ))}
            </Carousel>
          </div>
        </div>
      </div>
    </>
  );
};

export default YouMightAlsoLike;
