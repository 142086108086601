import React, { useState } from 'react';

const MWT_LOGO_LIGHT = '/images/logo/logo-main.webp'; // Replace with actual logo URL
const MWT_LOGO_DARK = '/images/logo/logo-main-light.png'; // Replace with actual logo URL

const BlurredImage = ({ src, alt, className, ...props }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);

  return (
    <div className='relative overflow-hidden'>
      <img
        src={hasError || !src ? MWT_LOGO_LIGHT : src}
        alt={alt || 'MWT Logo'}
        className={` w-full h-auto object-cover transition-all duration-700 ease-in-out  ${hasError || !src ? "scale-50" : ""}
          ${
            isLoaded
              ? 'blur-0 scale-100 opacity-100'
              : 'blur-md scale-105 opacity-50'
          } 
          ${className}`}
        onLoad={() => setIsLoaded(true)}
        onError={() => setHasError(true)}
        {...props}
      />

      {/* Dark Mode Logo - Only shown in dark mode */}
      {hasError && (
        <img
          src={MWT_LOGO_DARK}
          alt='MWT Logo Dark'
          className='w-full h-auto object-cover absolute inset-0 hidden dark:block'
        />
      )}
    </div>
  );
};

export default BlurredImage;
