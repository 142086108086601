import storage from 'redux-persist/lib/storage';
import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from 'redux-persist';
import auth from "./authSlice";
import event from './eventSlice';
import { api } from '../http/apiConfig';
const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, auth)

export const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        auth: persistedReducer,
        event: event
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(api.middleware),
})

export const persistor = persistStore(store)
