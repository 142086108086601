import React from 'react';
import MapComponent from '../../../components/GoogleMap/Map';
import TrendingEvents from './TrendingEvents';
import ScrollToTop from '../../../components/ScrollToTop/ScrollToTop';

const EventsRightSection = ({
  handleMarkerClick,
  setMapAddress,
  coordinates,
  selectedLocation,
  mapAddress,
  trending,
}) => {
  return (
    <div>
      <div className='rounded-md flex-1'>
        <MapComponent
          onlyMarkerClick={true}
          onMarkerClick={handleMarkerClick}
          enableClick={true}
          setMapAddress={setMapAddress}
          coordinates={coordinates}
          selectedLocation={selectedLocation}
          mapSize={'300px'}
          zoom={8}
        />
        {(mapAddress.lat != null || mapAddress.lng != null) && (
          <div className='w-full flex justify-end items-center mt-1'>
            <button
              onClick={() => setMapAddress({ lat: null, lng: null })}
              type='button'
              className='text-white bg-[#C0A04C] hover:bg-[#A48533] dark:hover:bg-[#A48533] hover:text-white rounded text-xs py-1 px-2 text-center dark:bg-[#C0A04C]'
            >
              Reset Map
            </button>
          </div>
        )}
      </div>
      <div className='md:flex flex-col w-full mt-3'>
        <TrendingEvents trending={trending} />

        <ScrollToTop />
      </div>
    </div>
  );
};

export default EventsRightSection;
