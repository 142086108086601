import moment from 'moment';
import { forwardRef } from 'react';

export const CustomDateInput = forwardRef(
  ({ value, onClick, filterDate, clearQueryResult }, ref) => (
    <button
      onClick={onClick}
      ref={ref}
      className='flex items-center cursor-pointer bg-gray-50 border border-gray-300 text-black placeholder-gray-500 text-sm rounded-lg focus:ring-[#C0A04C] focus:border-[#C0A04C] p-2 dark:bg-[#454545] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#C0A04C] dark:focus:border-[#C0A04C] w-40'
    >
      {filterDate ? (
        <div className='w-full flex align-middle items-center justify-between'>
          <p className='dark:text-white text-[#A48533] font-semibold'>
            {moment(filterDate).format('DD-MM-YYYY')}
          </p>
          <div onClick={() => clearQueryResult()}>
            <img
              className='h-4 ml-2 flex dark:hidden'
              src='/images/icons/cancel-icon.png'
              alt=''
            />
            <img
              className='h-4 ml-2 dark:flex hidden'
              src='/images/icons/cancel-icon-light.png'
              alt=''
            />
          </div>
        </div>
      ) : (
        <div className='w-full flex justify-between items-center'>
          <p className='text-gray-500 dark:text-white'>Calendar</p>
          <img
            className='w-5 h-5 flex dark:hidden'
            src='/images/icons/calendar.png'
            alt=''
          />
          <img
            className='w-5 h-5 hidden dark:flex'
            src='/images/icons/calendar-light.png'
            alt=''
          />
        </div>
      )}
    </button>
  )
);
