import React, { useState, useEffect, memo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useGetAllCategoriesQuery } from '../../../../store/services/categorySlice';

const WhereTo = () => {
  const { data, isLoading } = useGetAllCategoriesQuery();
  const [showAll, setShowAll] = useState(false);
  const [categories, setCategories] = useState([]);

  // Fetch categories on mount
  useEffect(() => {
    if (data) {
      const categoriesToRemove = new Set([
       '67440126163b6b564138d634',
        '67469afe163b6b56413c602d',
        '6729f86fb2035187befa6a6b',
        '67a9f4e8612da9c7cc593b0e',
        '67aac290612da9c7cc5b16b6',
        '67c1fc57ad763557db384ea2',
        '678617c33c8e31dd78254f6c',
      ]);
      setCategories(
        data.data.filter((category) => !categoriesToRemove.has(category._id))
      );
    }
  }, [data]);

  const visibleCategories = showAll ? categories : categories.slice(0, 6);

  // Memoized toggle for showing more/less
  const toggleShowAll = useCallback(() => {
    setShowAll((prevState) => !prevState);
  }, []);

  console.log(categories);
  // Rendering component
  return (
    <section className='flex justify-center items-center py-5'>
      <section className='w-full md:w-full sm:mx-5 lg:w-10/12 xl:w-9/12 2xl:w-7/12'>
        {!isLoading && visibleCategories.length > 0 && (
          <div className='flex justify-between mb-3'>
            <span className='ml-7 text-xl font-bold md:text-2xl md:font-[700]'>
              Where To ?
            </span>
          </div>
        )}

        <div className='mx-5 grid grid-cols-2 md:grid-cols-3 gap-3 p-3'>
          {!isLoading &&
            visibleCategories.map((category) => (
              <div
                key={category.categoryURL}
                className='h-40 md:h-60 grid md:grid-cols-1 grid-rows-2 gap-3'
              >
                <Link to={`/category/${category.categoryURL}`}>
                  <div className='relative'>
                    <LazyLoadImage
                      className='rounded-md h-40 md:h-60 w-full bg-gray-400 bg-blend-multiply hover:bg-grey-500 grayscale-10'
                      src={category.photo}
                      alt={category.name}
                      isLoading='lazy'
                    />
                    <div className='rounded absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black opacity-50'></div>
                    <span className='absolute bottom-0 left-0 text-white p-2 font-bold'>
                      {category.name}
                    </span>
                  </div>
                </Link>
              </div>
            ))}
        </div>

        {!isLoading && visibleCategories.length > 0 && (
          <div className='flex justify-end mx-5'>
            <button
              className='mr-2 dark:hover:bg-gray-500 hover:bg-slate-100 dark:bg-[#454545] bg-[#F3F3F3]  rounded-md py-2 px-3'
              onClick={() => setShowAll(!showAll)}
            >
              <p className='text-sm font-medium underline underline-offset-1'>
                {showAll ? 'View less' : 'View more'}
              </p>
            </button>
          </div>
        )}
      </section>
    </section>
  );
};

export default memo(WhereTo);
