import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import CategoryCard from '../../../../components/Cards/CategoryCard';
import CategorySkeleton from '../../../../components/shared/skeletons/CategorySkeleton';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useGetCategoryCountQuery } from '../../../../store/services/categorySlice';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './styles.css';

import { FreeMode, Pagination, Navigation } from 'swiper/modules';

const PopularCategory = () => {
  const [category, setCategory] = useState('');
  const containerRef = useRef(null);
  const { data, isLoading: categoryLoading } = useGetCategoryCountQuery();
  const navigate = useNavigate();

  const daysAndDates = [];

  for (let i = 0; i <= 6; i++) {
    const day = moment().clone().add(i, 'days');
    daysAndDates.push({
      day: day.format('dddd'),
    });
  }

  useEffect(() => {
    if (data) {
      setCategory(data);
    }
  }, [data]);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  return (
    <div className='flex justify-center items-center align-middle mt-5'>
      <section className='w-full md:w-full sm:mx-5 md:mx-10 lg:w-10/12 md:w-8.5/12 xl:w-8.5/12 2xl:w-7/12'>
        <div className='flex justify-between align-middle items-center'>
          {category.data != null || category.data != undefined ? (
            <>
              {category.data.length !== 0 && (
                <>
                  <div className='mx-3 left flex items-center align-middle '>
                    <h2 className='text-xl font-bold md:text-2xl md:font-[700]'>
                      Popular Categories
                    </h2>
                  </div>

                  {/* <div className='hidden right md:flex space-x-2'>
                    {daysAndDates.map((e) => (
                      <button
                        onClick={() =>
                          navigate(`category/weeklyoffers?subcategory=${e.day}`)
                        }
                        className={`h-7 md:block hover:bg-black hover:text-white dark:hover:bg-white dark:hover:text-black dark:hover:text-black hover:text-white rounded-full  dark:border-white px-3 py-1 text-xs border border-black`}
                      >
                        {e.day}
                      </button>
                    ))}

                    <div className='block selectoption pr-2 md:hidden'>
                      <select
                        id='countries'
                        className='bg-black border border-gray-300 text-white text-sm rounded-full focus:ring-[#C0A04C] focus:border-[#C0A04C] block w-full p-1.5 px-3.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#C0A04C] dark:focus:border-[#C0A04C]'
                      >
                        {daysAndDates.map((e) => (
                          <option
                            key={e.date} // Make sure to add a unique key prop when mapping in React
                            value={e.date}
                            className={`hover:bg-[#C0A04C] hover:text-white rounded-full border-black px-3 py-1 text-xs border`}
                          >
                            {e.day}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div> */}
                </>
              )}
            </>
          ) : (
            <>
              <>
                <div className='mx-3 left flex items-center align-middle '>
                  <span className='text-xl font-bold md:text-2xl md:font-[700]'>
                    Popular Categories
                  </span>
                </div>

                <div className='hidden right md:flex space-x-2'>
                  {daysAndDates.map((e) => (
                    <button
                      onClick={() =>
                        navigate(`category/weeklyoffers?subcategory=${e.day}`)
                      }
                      className={`h-7 md:block hover:bg-black hover:text-white dark:hover:bg-white dark:hover:text-black  rounded-full  dark:border-white px-3 py-1 text-xs border border-black`}
                    >
                      {e.day}
                    </button>
                  ))}

                  <div className='block selectoption pr-2 md:hidden'>
                    <select
                      id='countries'
                      className='bg-black border border-gray-300 text-white text-sm rounded-full focus:ring-[#C0A04C] focus:border-[#C0A04C] block w-full p-1.5 px-3.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#C0A04C] dark:focus:border-[#C0A04C]'
                    >
                      {daysAndDates.map((e) => (
                        <option
                          key={e.date} // Make sure to add a unique key prop when mapping in React
                          value={e.date}
                          className={`hover:bg-[#C0A04C] hover:text-white rounded-full border-black px-3 py-1 text-xs border`}
                        >
                          {e.day}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </>
            </>
          )}
        </div>

        <div>
          <div
            id='content'
            ref={containerRef}
            className=' flex w-full overflow-x-auto mt-5'
          >
            {category.data == null || category.data == undefined ? (
              <div className='pl-3 h-30 flex space-x-5'>
                <CategorySkeleton />
                <CategorySkeleton />
                <CategorySkeleton />
                <CategorySkeleton />
              </div>
            ) : categoryLoading ? (
              <>
                <div className='pl-3 h-30  flex space-x-5'>
                  <CategorySkeleton />
                  <CategorySkeleton />
                  <CategorySkeleton />
                  <CategorySkeleton />
                </div>
              </>
            ) : category.data.length === 0 ? (
              <div className='flex justify-center'>
                {/* <img className='h-60' src="/images/assets/logo-main.png" alt="" /> */}
              </div>
            ) : (
              <div className=' w-full'>
                <Swiper
                  slidesPerView={4}
                  spaceBetween={5}
                  freeMode={true}
                  navigation={true}
                  modules={[FreeMode, Pagination, Navigation]}
                  className='mySwiper'
                  breakpoints={{
                    3000: { slidesPerView: 4 },
                    1024: { slidesPerView: 4 },
                    464: { slidesPerView: 2 },
                    0: { slidesPerView: 2 },
                  }}
                >
                  {category.data.map((category) => {
                    return (
                      <SwiperSlide>
                        <Link
                          to={`/category/${category.categoryURL}`}
                          key={category._id}
                        >
                          <div className='mx-3'>
                            <CategoryCard data={category} />
                          </div>
                        </Link>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            )}
          </div>
        </div>

        <div className='grid md:grid-cols-3 flex align-middle'>
          <div className=''></div>

          <div className=' hidden md:flex  justify-center items-center space-x-4'>
            <></>
          </div>

          {category.data != null || category.data != undefined ? (
            <>
              {category.data.length !== 0 && (
                <>
                  <div className=' flex justify-end space-x-1 align-middle items-center mr-3 mt-1'>
                    <div className='flex justify-end align-middle items-center'>
                      <Link
                        className='w-30 dark:hover:bg-gray-500 hover:bg-slate-100 rounded-md py-2 px-4 flex justify-center align-middle items-center'
                        to='/whereto'
                      >
                        <img
                          className='dark:hidden flex h-6 mr-1'
                          src='/images/icons/map.svg'
                          alt=''
                        />
                        <img
                          className='hidden dark:flex h-6 mr-1'
                          src='/images/icons/homeMap-light.svg'
                          alt=''
                        />
                        <p className='text-sm font-medium '>View map</p>
                      </Link>
                    </div>
                    <div className='dark:hover:bg-gray-500 hover:bg-slate-100 dark:bg-[#454545] bg-[#F3F3F3]  rounded-md py-2 px-3'>
                      <Link className='' to='/category'>
                        <p className='font-medium underline underline-offset-1  text-sm font-medium '>
                          view all
                        </p>
                      </Link>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <>
                <div className=' flex justify-end space-x-1 align-middle items-center'>
                  <div className='flex justify-end align-middle items-center'>
                    <Link
                      to='/whereto'
                      className='w-30 dark:hover:bg-gray-500 hover:bg-slate-100 rounded-md py-2 px-4 flex justify-center align-middle items-center'
                    >
                      <img
                        className='dark:hidden flex h-6 mr-1'
                        src='/images/icons/map.svg'
                        alt=''
                      />
                      <img
                        className='hidden dark:flex h-6 mr-1'
                        src='/images/icons/homeMap-light.svg'
                        alt=''
                      />
                      <p className='text-sm font-medium '>View map</p>
                    </Link>
                  </div>
                  <div className=''>
                    <Link
                      className='w-30 dark:hover:bg-gray-500 hover:bg-slate-100 rounded-md py-2 px-3 flex justify-center align-middle items-center'
                      to='/category/events'
                    >
                      <p className='font-medium underline underline-offset-1  pr-2 text-sm font-medium '>
                        view all
                      </p>
                    </Link>
                  </div>
                </div>
              </>
            </>
          )}
        </div>
      </section>
    </div>
  );
};

export default PopularCategory;
