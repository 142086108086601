import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_IP, // Replace with your API URL
});

export const api = createApi({
    reducerPath: "api",
    baseQuery,
    endpoints: () => ({}),
});

export default api.middleware