import { useState } from "react";
import { useNavigate } from "react-router-dom";

const NavigationComponent = () => {
    const [openSection, setOpenSection] = useState(null);

    const navigate = useNavigate()
    
    const toggleSection = (section) => {
        setOpenSection(openSection === section ? null : section);
    };

    return (
        <div className="grid grid-cols-1 gap-8 lg:col-span-2 sm:grid-cols-2 lg:grid-cols-3 justify-items-center">

            {/* Popular Navigation */}
            <div className="flex justify-center lg:justify-start">
                <div className="text-center lg:text-left">
                    <p
                        className="font-semibold cursor-pointer lg:cursor-default"
                        onClick={() => toggleSection('popular')}
                    >
                        Popular Navigation
                    </p>
                    <nav
                        className={`flex-col mt-4 space-y-5 text-sm ${openSection === 'popular' ? 'flex' : 'hidden'
                            } md:flex`}
                    >
                        <a onClick={() => navigate("/category/iftar")} className="hover:opacity-75 font-medium cursor-pointer" href="#"> Iftar </a>
                        <a onClick={() => navigate("/category/suhour")} className="hover:opacity-75 font-medium cursor-pointer" href="#"> Suhour </a>
                        <a onClick={() => navigate("/category/staycation")} className="hover:opacity-75 font-medium cursor-pointer" href="#"> Staycation </a>
                    </nav>
                </div>
            </div>

            {/* Information */}
            <div className="flex justify-center lg:justify-start">
                <div className="text-center lg:text-left">
                    <p
                        className="font-semibold cursor-pointer lg:cursor-default"
                        onClick={() => toggleSection('information')}
                    >
                        Information
                    </p>
                    <nav
                        className={`flex-col mt-4 space-y-5 text-sm ${openSection === 'information' ? 'flex' : 'hidden'
                            } md:flex`}
                    >
                        <a onClick={() => navigate("/about")} className="hover:opacity-75 font-medium cursor-pointer" href="#"> About </a>
                        <a onClick={() => navigate("/contact")} className="hover:opacity-75 font-medium cursor-pointer" href="#"> Contact Us </a>
                        <a onClick={() => navigate("/faq")} className="hover:opacity-75 font-medium cursor-pointer" href="#"> FAQ </a>
                    </nav>
                </div>
            </div>

            {/* The Legal Corner */}
            <div className="flex justify-center lg:justify-start">
                <div className="text-center lg:text-left">
                    <p
                        className="font-semibold cursor-pointer lg:cursor-default"
                        onClick={() => toggleSection('legal')}
                    >
                        The Legal Corner
                    </p>
                    <nav
                        className={`flex-col mt-4 space-y-5 text-sm ${openSection === 'legal' ? 'flex' : 'hidden'
                            } md:flex`}
                    >
                        <a onClick={() => navigate("/user/privacypolicy")} className="hover:opacity-75 font-medium cursor-pointer" href="#"> Privacy policy </a>
                        <a onClick={() => navigate("/user/termsandconditions")} className="hover:opacity-75 font-medium cursor-pointer" href="#"> Terms and conditions </a>
                        <a onClick={() => navigate("/user/cookies")} className="hover:opacity-75 font-medium cursor-pointer" href="#"> Cookies </a>
                    </nav>
                </div>
            </div>
        </div>
    );
};

export default NavigationComponent;
