import { api } from "../../http/apiConfig";


export const categoryApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAllCategories: builder.query({
            query: () => "/category/all",
        }),
        getCategoryCount: builder.query({
            query: (params) => `/category/eventcounts`,
        }),
    }),
});

export const {
    useGetAllCategoriesQuery,
    useGetCategoryCountQuery
} = categoryApi;
