import React from 'react';
import TrendingCard from '../../../components/Cards/TrendingCard';
import { useGetTrendingEventsQuery } from '../../../store/services/eventSlice';

const TrendingEvents = () => {
  const { data: trendingEvents, isLoading: trendingEventsLoading } =
    useGetTrendingEventsQuery();
  return (
    <div>
      {!trendingEventsLoading &&
        trendingEvents.data != null &&
        trendingEvents.data.length > 0 && (
          <>
            <div className='mt-3'>
              <p className='text-xl font-bold mt-3'>Trending in Muscat</p>
            </div>
            <div>
              {trendingEvents.data.map(
                (event) =>
                  event != null && <TrendingCard key={event.id} data={event} />
              )}
            </div>
          </>
        )}
    </div>
  );
};

export default TrendingEvents;
