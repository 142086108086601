import React from 'react';

const Banner = () => {
  const handleStoreRedirect = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) {
      window.location.href =
        'https://play.google.com/store/apps/details?id=your.app.id';
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href =
        'https://apps.apple.com/in/app/muscat-where-to/id6444629353';
    } else {
      window.location.href =
        'https://play.google.com/store/apps/details?id=com.muscatwhereto.android';
    }
  };

  return (
    <>
      {window.isNative != true && (
        <img
          className='pb-5 h-16 w-full md:h-auto'
          src='/images/assets/download.png'
          alt=''
          onClick={handleStoreRedirect}
        />
      )}
    </>
  );
};

export default Banner;
