import React, { useState } from 'react';
import Navbar from '../../../components/shared/Navbar/Navbar';
import Footer from '../../../components/shared/Footer/Footer';
import { ClientContactUs } from '../../../http/index';
import toast from 'react-hot-toast';
import validator from 'validator';
import ScrollToTop from '../../../components/ScrollToTop/ScrollToTop';
import BottomNav from '../../../components/shared/BottomNav/BottomNav';
import { useNavigate } from 'react-router-dom';
const Contactus = () => {
  document.title = 'Contact us';

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  async function submit() {
    if (!firstname || !lastname || !email || !message) {
      toast.error('All field are mandatory');
    } else if (!validator.isEmail(email)) {
      toast.error('Enter valid Email address');
    } else {
      setLoading(true);
      try {
        const contactdata = {
          firstname: firstname,
          lastname: lastname,
          email: email,
          message: message,
        };
        const res = await ClientContactUs(contactdata);
        setLoading(false);
        toast.success('Message sent');
        setFirstname('');
        setLastname('');
        setEmail('');
        setMessage('');
      } catch (error) {
        setLoading(false);
        toast.error(error.response.data.data);
      }
    }
  }

  return (
    <div className='contactmargine dark:bg-[#2c2c2c] dark:text-white'>
      <Navbar />
      <section className='md:mr-48 md:ml-48 mt-3 mb-10'>
        <div className='flex align-middle items-center'>
          <button onClick={() => navigate(-1)} className=' mt-1'>
            <img
              className='h-14 w-14'
              src='/images/icons/back-button.png'
              alt=''
            />
          </button>
          <p className='text-xl font-bold'>Contact Us</p>
        </div>

        <div className='flex justify-center mt-5'>
          <div className='m-4 space-y-4 bg-[#D0D0D0] dark:bg-[#454545] dark:border-[#454545] dark:text-white border border-b-4 border-b-[#C0A04C] dark:border-b-[#C0A04C] p-5 rounded-lg '>
            <div className='space-y-5 '>
              <div className='flex flex-col text-center justify-center items-center space-y-2'>
                <span className='text-xl font-bold'>
                  Get in Touch with Muscat Where To!
                </span>
                <p className='text-base font-medium mb-3'>
                  Looking for the hottest offers and events in Muscat? Have an
                  awesome promotion or event you want to feature on our site?
                  Contact us using the form below and let's connect to make sure
                  your offer gets the spotlight it deserves!{' '}
                </p>
              </div>

              <div className=' mx-auto  '>
                <div className='flex flex-col md:flex-row md:space-x-5'>
                  <div className='md:w-1/2'>
                    <div className='flex flex-col bg-white dark:bg-[#2c2c2c] pl-2 pr-2 rounded-md'>
                      <label className='text-xs mt-1' htmlFor='first name'>
                        FIRST NAME
                      </label>
                      <input
                        type='text'
                        value={firstname}
                        className='ring-0 dark:border-0 dark:text-white border bg-transparent border-white focus:border-white focus:ring-white  outline-0 text-sm font-medium text-black'
                        onChange={(e) => setFirstname(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className='md:w-1/2 mt-5 md:mt-0'>
                    <div className='flex flex-col bg-white dark:bg-[#2c2c2c] pl-2 pr-2 rounded-md'>
                      <label className='text-xs mt-1' htmlFor='first name'>
                        LAST NAME
                      </label>
                      <input
                        type='text'
                        value={lastname}
                        className='ring-0 dark:border-0 dark:text-white border bg-transparent border-white focus:border-white focus:ring-white  outline-0 text-sm font-medium text-black'
                        onChange={(e) => setLastname(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className='row2 mt-4'>
                  <div className='flex flex-col bg-white dark:bg-[#2c2c2c] pl-2 pr-2 rounded-md'>
                    <label className='text-xs mt-1' htmlFor='first name'>
                      EMAIL
                    </label>
                    <input
                      type='email'
                      value={email}
                      className='ring-0 dark:border-0 dark:text-white border bg-transparent border-white focus:border-white focus:ring-white  outline-0 text-sm font-medium text-black'
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className='row3 mt-4 '>
                  <div className='flex flex-col bg-white dark:bg-[#2c2c2c] pl-2 pr-2 rounded-md'>
                    <textarea
                      type='text'
                      value={message}
                      placeholder='Type your message here'
                      className='ring-0 dark:border-0 dark:text-white border h-24 min-h-[96px] max-h-80 bg-transparent border-white focus:border-white focus:ring-white outline-0 text-sm font-medium text-black'
                      onChange={(e) => {
                        if (e.target.value.length <= 1200) {
                          setMessage(e.target.value);
                        }
                      }}
                      required
                    />
                    <p className='text-xs text-gray-400'>
                      {message.length}/1200
                    </p>
                  </div>
                </div>
                <div className='flex justify-center mt-5'>
                  <button
                    onClick={submit}
                    type='button'
                    className='text-white bg-[#C0A04C] hover:bg-[#A48533] hover:text-white font-medium rounded-lg text-sm px-4 w-40 py-2 text-center mr-3 md:mr-0 dark:bg-[#C0A04C] dark:hover:bg-[#A48533] dark:focus:ring-[#A48533]'
                  >
                    Send Message
                  </button>
                </div>
                <div className='flex flex-col justify-center items-center mt-5 space-y-2'>
                  <span className='text-sm font-bold'>IMPORTANT!</span>
                  <p className='text-sm font-normal'>
                    If you have not heard from us, check your spam folder or
                    contact us directly at
                  </p>
                  <a
                    href='mailto:info@muscatwhereto.com'
                    className='font-bold text-sm'
                  >
                    info@muscatwhereto.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='relative ml-4 mt-4 '>
          <span className='font-bold text-lg md:ml-4'>
            Follow us on Social Media
          </span>
          <img className='ml-24 h-8' src='/images/assets/arrow.png' alt='' />
          <div className='ml-36 flex space-x-3 socialmedia'>
            <a
              href='https://wa.me/+96891738405'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img className='h-7' src='/images/icons/wp-a.svg' alt='' />
            </a>

            <a
              href='https://www.facebook.com/muscatwhereto'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img className='h-7' src='/images/icons/fb-a.svg' alt='' />
            </a>

            <a
              href='https://www.instagram.com/muscat_whereto/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img className='h-7' src='/images/icons/ig-a.svg' alt='' />
            </a>

            <a href='mailto:info@muscatwhereto.com'>
              <div className='flex justify-center align-middle items-center h-7 w-7 rounded-full border-2 dark:border-white border-black'>
                <div>
                  <i class='h-4 font-bold dark:font-medium text-black dark:text-white ri-mail-line bg-[#C0A04C] hover:bg-[#A48533]'></i>
                </div>
              </div>
            </a>
          </div>

          <ScrollToTop />
        </div>
      </section>

      <div className=''>
        <Footer />
      </div>

      <div>
        <BottomNav />
      </div>
    </div>
  );
};

export default Contactus;
