import { useNavigate } from 'react-router-dom';
import {
  GetVendorNotification,
  DeleteVendorNotification,
  AdminSendNotification,
} from '../../http/index';
import toast  from 'react-hot-toast';
import React, { useState, useEffect, useRef } from 'react';
import Sidebar from '../../components/shared/Sidebar/Sidebar';
import AdminNavbar from '../../components/shared/Navbar/AdminNavbar';

const Notifications = () => {
  const [response, setResponse] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [message, setMessage] = useState('');
  const [user, setUser] = useState(false);
  const [vendor, setVendor] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);

  async function notificationModal() {
    setUser(false);
    setVendor(false);
    setMessage(false);
    setDisplayModal(!displayModal);
  }

  useEffect(() => {
    const fetchdata = async () => {
      try {
        const { data } = await GetVendorNotification();
        console.log(data.data);
        setResponse(data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchdata();
  }, [refresh]);

  const deleteNotification = async () => {
    try {
      // Use toast.promise to display a promise-based toast
      const promise = DeleteVendorNotification();
      const res = await toast.promise(promise, {
        loading: 'Deleting notifications...',
        success: (response) => response.data.data,
        error: (error) => `Error: ${error.response.data.data}`,
      });

      // Refresh or update the UI as needed after successful deletion
      if (res.data.success === true) {
        setRefresh(!refresh);
      }
    } catch (error) {
      // toast.error(error.response.data.data);
      console.error(error);
    }
  };

  const handleSubmit = async () => {
    if (user == false && vendor == false) {
      return toast.error('Please select either User or Vendor.');
    }

    try {
      const notificationdata = {
        user: user,
        vendor: vendor,
        msg: message,
      };
      // Use toast.promise to display a promise-based toast
      const promise = AdminSendNotification(notificationdata);
      const res = await toast.promise(promise, {
        loading: 'Sending Notifications....',
        success: (response) => response.data.data,
        error: (error) => `Error: ${error.response.data.data}`,
      });
      setDisplayModal(false);
    } catch (error) {
      // toast.error(error.response.data.data);
      console.error(error);
    }
  };

  return (
    <div>
      <div>
        <Sidebar />
      </div>
      <div className='flex '>
         

        <div className='pl-20 flex flex-col w-full'>
          <div className='mx-4'>
            <AdminNavbar />
            <hr className='mb-3' />
          </div>
          <div className='headline '>
            <div className='heading'>
              <div className='flex justify-between'>
                <span className='ml-4 text-2xl font-semibold'>
                  Notifications
                </span>
              </div>
              <hr className='mt-3 mb-3' />

              <div className='maincontent flex flex-col'>
                <section className='md:mr-20 md:ml-20 mt-5 ml-6 mr-6'>
                  <div className='flex align-middle items-center space-x-4 justify-end'>
                    {response.data != null && response.data.length > 0 && (
                      <button
                        onClick={() => deleteNotification()}
                        type='button'
                        className='text-white bg-[#C0A04C] hover:bg-[#A48533] hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center mr-3 md:mr-0 dark:bg-[#C0A04C] dark:hover:bg-white dark:focus:ring-blue-800'
                      >
                        Clear All
                      </button>
                    )}
                    <button
                      onClick={() => notificationModal()}
                      type='button'
                      className='text-white bg-[#C0A04C] hover:bg-[#A48533] hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center mr-3 md:mr-0 dark:bg-[#C0A04C] dark:hover:bg-white dark:focus:ring-blue-800'
                    >
                      Send Notification
                    </button>
                  </div>
                  <div className='mt-2 w-full flex flex-col space-y-4'>
                    {response.data == null ? (
                      <div className='h-screen w-full flex justify-center align-middle items-center'>
                        <div className='relative flex justify-center items-center'>
                          <div className='absolute animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-[#C0A04C]'></div>
                          <img
                            src='/images/logo/logo-main.png'
                            className='h-16'
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        {response.data.map((response, index) => (
                          <div
                            key={index}
                            className='w-full bg-[#DDDDDD] h-auto p-2 rounded-lg'
                          >
                            <p className='text-sm pl-2 pr-2 leading-6'>
                              {response}
                            </p>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                  {displayModal ? (
                    <div className='fixed inset-5 flex justify-center z-50 overflow-auto bg-[#FFFFFF] bg-opacity-20 backdrop-blur-sm'>
                      <div className='relative rounded-lg '>
                        <section className='md:mt-12 flex bg-white drop-shadow-2xl rounded-lg'>
                          <div className='w-96 md:w-[35rem]'>
                            <div className='modal bg-white px-5 py-5'>
                              <span className='ml-0 font-medium text-md'>
                                Send Notification
                              </span>
                              <div className='flex space-x-3 align-middle items-center'>
                                <div className='flex  items-center pl-3'>
                                  <input
                                    type='checkbox'
                                    value={user}
                                    onChange={() => setUser(!user)}
                                    className='w-4 h-4 rounded text-[#A48533] focus:ring-[#A48533]'
                                  />
                                  <label className='w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300'>
                                    User
                                  </label>
                                </div>
                                <div className='flex  items-center pl-3'>
                                  <input
                                    type='checkbox'
                                    value={vendor}
                                    onChange={() => setVendor(!vendor)}
                                    className='w-4 h-4 rounded text-[#A48533] focus:ring-[#A48533]'
                                  />
                                  <label className='w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300'>
                                    Vendor
                                  </label>
                                </div>
                              </div>

                              <div className='flex flex-col border border-black border-1 dark:bg-[#2c2c2c] pl-2 pr-2 rounded-md'>
                                <label
                                  className='text-xs mt-1'
                                  htmlFor='first name'
                                >
                                  Message
                                </label>
                                <textarea
                                  type='text'
                                  // value={message}
                                  className='ring-0 dark:border-0 dark:text-white border h-24 bg-transparent border-0 focus:border-0 focus:ring-0  outline-0 text-sm font-medium text-black'
                                  onChange={(e) => setMessage(e.target.value)}
                                  required
                                />
                              </div>
                              <div className='mt-2'>
                                <button
                                  className='w-full py-2 bg-[#C0A04C] hover:bg-[#A48533] rounded-md text-sm font-bold text-gray-50 transition duration-200'
                                  onClick={handleSubmit}
                                >
                                  Send Notification
                                </button>
                              </div>
                            </div>
                          </div>
                        </section>
                        <button
                          onClick={notificationModal}
                          className='absolute top-3 -right-5 m-2 text-gray-600 hover:text-gray-800 focus:outline-none'
                        >
                          <img src='/images/icons/cancel-icon.png' alt='' />
                        </button>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
