import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import {
  addToFavorites,
  ClientGetOffers,
  CategoryCount,
  ClientUpcomingEvents,
  getCategoryEvents,
} from '../../http/index';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { enqueueApiRequest } from '../../utils/Apiqueue';

const FavoriteCard = ({ data, width, refetchData }) => {
  document.title = 'favorites';

  const [isLiked, setIsLiked] = useState(false);

  const { user, isAuth } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is logged in and the event.likes array includes the user's ID
    let liked = isAuth && data.likes.includes(user._id);

    // Check if the card is in the local storage favorites
    const favorites = JSON.parse(localStorage.getItem('favorites')) || [];
    if (favorites.includes(data._id)) {
      liked = true;
    }

    setIsLiked(liked);
  }, [data.likes, user, isAuth, data._id]);

  const favoriteFeature = async (eventid) => {
    const toggleFavoriteInLocalStorage = (eventid) => {
      let favorites = JSON.parse(localStorage.getItem('favorites')) || [];

      if (favorites.includes(eventid)) {
        // Remove the event from favorites
        favorites = favorites.filter((fav) => fav !== eventid);
        toast.success('Removed from favorites');
      } else {
        // Add the event to favorites
        favorites.push(eventid);
        toast.success('Added to favorites');
      }

      localStorage.setItem('favorites', JSON.stringify(favorites));
      return favorites.includes(eventid); // Return the new state
    };

    try {
      if (window.isNative && !user) {
        const newIsLiked = toggleFavoriteInLocalStorage(eventid);
        setIsLiked(newIsLiked);
        return;
      }

      const eventdata = { eventid: eventid };
      const { data } = await addToFavorites(eventdata);
      setIsLiked(data.isLiked);
      toast.success(data.message);
    } catch (error) {
      console.error(error);
      if (error.response?.status === 401) {
        const currentPath = window.location.pathname;
        enqueueApiRequest(() => favoriteFeature(eventid));
        navigate('/login', { state: { from: currentPath } });
      } else {
        toast.error('An error occurred while updating favorites');
      }
    }
  };

  return (
    <>
      <div
        onClick={() => navigate(`/events/${data.slug}`)}
        className={`cursor-pointer relative mx-1 ${width} rounded-md bg-[#F3F3F3] dark:bg-[#454545] dark:text-white my-2`}
      >
        <div href='#'>
          <img
            className='rounded-md aspect-square'
            src={`${data.displayPhoto}`}
            alt=''
          />
        </div>

        <button
          onClick={(e) => {
            e.stopPropagation();
            favoriteFeature(data._id);
          }}
          className='absolute top-2 right-2 bg-white text-black rounded-full z-20 p-2'
        >
          {isLiked ? (
            <img className='' src='/images/icons/heart-fav.svg' alt='' />
          ) : (
            <img src='/images/icons/heart.svg' alt='' />
          )}
        </button>

        <div className='p-1 pt-4 pb-2 mx-1'>
          <div className=''>
            <p className='text-xss m:text-xs mt-1  font-medium truncate'>
              {data.title} at
            </p>
            {/* <p className='text-xss m:text-xs mt-1  font-medium truncate'>
              {data.location?.name || ''}
            </p> */}
             <p className='text-xs md:text-xs md:mt-1 text-[#C0A04C] font-medium truncate'>
            {data.location?.name || ''}
          </p>
          </div>
          <div>
            <p className='text-xss font-light md:font-normal'>
              {data.shortDescription}{' '}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
// xs:text-xm sm:
export default FavoriteCard;
