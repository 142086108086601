import { api } from "../../http/apiConfig";


export const eventsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAllDateEvents: builder.query({
            query: (data) => ({
                url: '/events/alldates',
                method: 'POST',
                body: data,
            }),
        }),
        getTrendingEvents: builder.query({
            query: (data) => ({
                url: '/trending-events',
                method: 'GET',
            }),
        }),
    }),
});

export const {
    useGetAllDateEventsQuery,
    useLazyGetAllDateEventsQuery,
    useGetTrendingEventsQuery
} = eventsApi;
